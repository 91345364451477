import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { finalize, Observable, of } from 'rxjs';

import { HomeSignalsService } from './home-signals.service';
import { SpinnerService } from './home-spinner.service';
import { MONITORED_ROUTES } from '../constants/home-endpoints.constants';

@Injectable()
export class HomeSubscriptionInterceptor implements HttpInterceptor {
  private monitoredEndpoints: string[];

  constructor(
    private readonly homeSignalsService: HomeSignalsService,
    private readonly spinnerService: SpinnerService
  ) {
    this.monitoredEndpoints = Object.values(MONITORED_ROUTES).map(
      (route) => route.root
    );
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isSubscribed = this.homeSignalsService.isSubscribed();
    const isMonitoredEndpoint = this.monitoredEndpoints.some((endpoint) =>
      request.url.includes(endpoint)
    );

    if (!isMonitoredEndpoint || isSubscribed) {
      this.spinnerService.showSpinner();

      return next
        .handle(request)
        .pipe(finalize(() => this.spinnerService.hideSpinner()));
    }

    return of();
  }
}
