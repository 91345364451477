<div class="content-wrapper">
  <div class="container">
    <div class="wrap_first createaccount pb-4">
      <div
        class="d-flex flex-column justify-content-center align-items-start h-100 mx-auto gap-2 gap-lg-4 crisis-wrapper"
      >
        <span class="ps-0 ps-md-3 ps-lg-5">
          <a class="crisis-mobile" [href]="'tel:' + crisisLine">
            <img
              src="assets/icons/mobile-phone.png"
              alt="phone"
              height="41px"
              width="25px"
            />
            {{ crisisLine }}
          </a>
        </span>
        <span class="crisis-mobile">
          {{ crisisLineDescription }}
        </span>
      </div>
    </div>
  </div>
</div>
