export const environment = {
  production: true,
  api_url: 'https://api.travelhealth-companion.com/api/v1/',
  redirectURL:'https://travelhealth-companion.com/login',
  linkedinClientId:"7817mi1monqcxi",
  userredirectURL:'https://travelhealth-companion.com',
  //profille%20email%20openid
  //r_liteprofile%20r_emailaddress"
  linkedinScope:"profile%20email%20openid",
  facebookId:"7162911383729274",
  googleId:"1026759770456-pdb05gdgqcr814rpp2vlaltbjrnn5th6.apps.googleusercontent.com",
  clientWebsiteurl:"https://www.fitfortravel.nhs.uk/home"
};
