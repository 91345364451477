import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from 'src/app/shared/services/api.httpservice';
import { ApiResponse } from '../home/interfaces/common.interface';
import { UNMONITORED_ROUTES } from '../home/constants/home-endpoints.constants';

interface CrisisLineApiResponse extends ApiResponse {
  responseData: CrisisLineResponseData;
}

interface CrisisLineResponseData {
  crisisLine: string;
  crisisLineDescription: string;
  message: string;
}

@Injectable({ providedIn: 'root' })
export class CrisisLineService {
  constructor(private readonly apiService: ApiService) {}

  public getCrisisLineDetails(): Observable<CrisisLineApiResponse> {
    return this.apiService.get(UNMONITORED_ROUTES.crisisLine.root);
  }
}
