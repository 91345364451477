import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { GoogleMapsModule } from '@angular/google-maps';

import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { HelpComponent } from './views/help/help.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { TermsConditionComponent } from './views/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { MapComponent } from './views/map/map.component';
import { HelpMComponent } from './views/help-m/help-m.component';
import { MyProfileComponent } from './views/my-profile/my-profile.component';
import { AboutMComponent } from './views/about-m/about-m.component';
import { TermsMComponent } from './views/terms-m/terms-m.component';
import { PolicyMComponent } from './views/policy-m/policy-m.component';
import { OrganisationHomeComponent } from './views/organisation-home/organisation-home.component';
import { CrisisLineComponent } from './views/crisis-line/crisis-line.component';
import { AppNotFoundComponent } from './views/app-not-found/app-not-found.component';
import { NgbDateParserFormatterExtProvider } from 'src/app/shared/extras/_index';
import { RootScreensPipe } from 'src/app/shared/extras/root-screens.pipe';
import { HttpTokenInterceptor } from './shared/interceptors';
import { HttpInterceptorService } from './shared/interceptors/http-interceptor.service';
import { HomeSubscriptionInterceptor } from './views/home/services/home-subscription.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    MyProfileComponent,
    HelpComponent,
    AboutUsComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    MapComponent,
    HelpMComponent,
    AboutMComponent,
    TermsMComponent,
    PolicyMComponent,
    OrganisationHomeComponent,
    CrisisLineComponent,
    AppNotFoundComponent,
    RootScreensPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
    SharedModule,
    BrowserAnimationsModule,
    AccordionModule,
    NgbModule,
    LazyLoadImageModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectCountryModule.forRoot('en'),
    AngularSvgIconModule.forRoot(),
    GoogleMapsModule,
    ToastrModule.forRoot({
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      timeOut: 3000,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NgbDateParserFormatterExtProvider,

    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },

    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HomeSubscriptionInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
