import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { map } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CrisisLineService } from './crisis-line.service';

@UntilDestroy()
@Component({
  selector: 'app-crisis-line',
  templateUrl: './crisis-line.component.html',
  styleUrls: ['./crisis-line.component.scss'],
})
export class CrisisLineComponent implements OnInit {
  public crisisLine: string = '';
  public crisisLineDescription: string = '';

  constructor(
    public readonly router: Router,
    private readonly crisisLineService: CrisisLineService
  ) {}

  ngOnInit(): void {
    this.crisisLineService
      .getCrisisLineDetails()
      .pipe(
        untilDestroyed(this),
        map((crisisLineResponse) => crisisLineResponse.responseData)
      )
      .subscribe(({ crisisLine, crisisLineDescription }) => {
        this.crisisLine = crisisLine;
        this.crisisLineDescription = crisisLineDescription;
      });
  }

  public back() {
    this.router.navigate(['/create-account']);
  }
}
