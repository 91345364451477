import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Country } from '@angular-material-extensions/select-country';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { ApisService } from '../../shared/services/api.service';
import { DataSharingService } from '../../shared/services/data-sharing.service';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  public editProfile: boolean = false;
  public userProfile: UserProfile = {
    firstName: '',
    lastName: '',
    email: '',
    code: '',
    gender: '',
    bloodGroup: '',
    allergies: '',
    emergencyContact: '',
    dob: '',
    img: '',
  };
  public disabled: boolean = true;
  public nationality: Country = {
    name: 'indonesia',
    alpha2Code: 'IND',
    alpha3Code: '',
    numericCode: '',
    callingCode: '',
  };
  public isShowDivIf = false;
  public getpath: any;
  public profilePic: any;
  public profilePic1: any = 'assets/img/user.png';
  public pdferror = false;
  private profilePicFile: any;
  private userProfileData: any;
  private data: any;
  private userNationality: any;
  private passportDetails: any;
  private checkModel: any;
  private codedata: any;
  private disableSubmit: any;

  constructor(
    private apiService: ApisService,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    public router: Router,
    private DataSharingService: DataSharingService
  ) {}

  ngOnInit(): void {
    (this.codedata = localStorage.getItem('alpha2Code')),
      this.SpinnerService.show();
    this.getInitialData();
  }

  // select country
  public onCountrySelected(country: Country) {
    this.nationality = country;
  }

  public editProfileInfo() {
    this.editProfile = !this.editProfile;
    this.disabled = false;
  }

  public toggleDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }

  public submitFormData() {
    this.SpinnerService.show();

    const formData = new FormData();
    formData.append('img', this.profilePicFile);
    formData.append('firstName',this.userProfile.firstName);
    formData.append('lastName',this.userProfile.lastName);
    formData.append('gender',this.userProfile.gender);
    // formData.append('nationality', this.nationality?.alpha2Code);
    formData.append('nationality', this.nationality?.alpha2Code);
    formData.append('bloodGroup', this.userProfile.bloodGroup);
    formData.append('allergies', this.userProfile.allergies);
    formData.append('emergencyContact', this.userProfile.emergencyContact);

    this.apiService.updateProfile(formData).subscribe((res) => {
      this.userNationality = res.responseData.userProfile;
      this.getInitialData();
      this.toastr.success('Updated Succesfully');
      //this.router.navigate(['/my-profile'])
      this.getInitialData();
      this.submitUserData();
      this.reloadComponent();

      setTimeout(() => {
        this.DataSharingService.updatedDataSelectionuserProfile(
          this.userProfileData
        );
      }, 2000);

      // window.location.reload()
    });
  }

  private reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate([currentUrl]);
    this.router.navigate(['/my-profile']);
  }

  private getInitialData() {
    this.apiService.getUserProfile().subscribe((res) => {
      this.userProfile = res.responseData.userProfile;
      this.passportDetails = res.responseData.userProfile.passportDetails;
      this.userProfileData = res.responseData;
      if (this.userProfile.bloodGroup === 'undefined') {
        this.userProfile.bloodGroup = '';
      }
      if (this.userProfile.allergies === 'undefined') {
        this.userProfile.allergies = '';
      }
      if (this.userProfile.emergencyContact === 'undefined') {
        this.userProfile.emergencyContact = '';
      }
      if (res.responseData.userProfile.nationality === 'undefined') {
        this.data = '';
      } else {
        this.data = res.responseData.userProfile.nationality;
      }

      this.nationality = {
        // name: '',
        // alpha2Code: this.data,
        name: this.data,
        alpha2Code: '',
        alpha3Code: '',
        numericCode: '',
        callingCode: '',
      };
      this.getpath = this.userProfile.img;

      this.profilePic1 = `${res.responseData.user_bP}${this.userProfile.img}`;

      this.SpinnerService.hide();
    });
  }

  private submitUserData() {
    const formData = new FormData();
    let passportDetails: any = {
      passportName:
        this.userProfile.firstName + '-' + this.userProfile.lastName,
      passportNationality: this.userNationality.nationality,
      passportDob: this.passportDetails.passportDob,
      passportNumber: this.passportDetails.passportNumber,
      passportPhoto: this.passportDetails.passportPhoto,
    };
    formData.append('passportDetails', JSON.stringify(passportDetails));

    this.apiService.updateUser(formData).subscribe((res) => {
      if (res.statusCode == 1) {
        this.disableSubmit = false;
      } else {
        this.toastr.error(res.error.responseMessage, 'Error');
        this.disableSubmit = false;
      }
    });
  }

  // private onChange1($event: any) {
  //   this.checkModel = $event.target.checked;
  // }

  // Changing profile pic
  // public changeProfilePic(event: any) {
  //   console.log(event);
  //   var file = document.querySelector('#fUpload');
  //   if (/\.(jpe?g|png|gif)$/i.test(event.target.files[0].name) === false) {
  //     this.pdferror = true;
  //     return;
  //   } else {
  //     this.pdferror = false;
  //   }

  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];

  //     this.profilePicFile = file;

  //     const reader = new FileReader();
  //     reader.onload = (e) => (this.profilePic = reader.result);

  //     reader.readAsDataURL(file);
  //   }
  // }
}

interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  code: string;
  gender: string;
  dob: string;
  bloodGroup: any;
  allergies: any;
  emergencyContact: any;
  img: string;
}
