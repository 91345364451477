import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private dataSource = new BehaviorSubject<any>('');
  data = this.dataSource.asObservable();

  updatedDatanotification(data: any) {
    this.dataSource.next(data);
  }
}
