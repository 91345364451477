import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppNotFoundComponent } from './views/app-not-found/app-not-found.component';
import { AboutMComponent } from './views/about-m/about-m.component';
import { HelpMComponent } from './views/help-m/help-m.component';
import { TermsMComponent } from './views/terms-m/terms-m.component';
import { PolicyMComponent } from './views/policy-m/policy-m.component';
import { CrisisLineComponent } from './views/crisis-line/crisis-line.component';
import { TermsConditionComponent } from './views/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { HelpComponent } from './views/help/help.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { MyProfileComponent } from './views/my-profile/my-profile.component';
import { MapComponent } from './views/map/map.component';
import { AuthGuardService as AuthGuard } from './shared/services/auth.guard.service';

const LandingPageModule             = () => import('./views/landing-page/landing-page.module')                      .then((m) => m.LandingPageModule);
const SignUpModule                  = () => import('./views/signup/signup.module')                                  .then((m) => m.SignupModule);
const RootModule                    = () => import('./views/create-account/create-account.module')                  .then((m) => m.RootModule);
const ScannerModule                 = () => import('./views/scanner/scanner.module')                                .then((m) => m.ScannerModule);
const LoginModule                   = () => import('./views/login/login.module')                                    .then((m) => m.LoginModule);
const AccountTypeModule             = () => import('./views/account-type/account-type.module')                      .then((m) => m.AccountTypeModule);
const PasswordModule                = () => import('./views/password/password.module')                              .then((m) => m.PasswordModule);
const MyVaccinationModule           = () => import('./views/my-vaccination/my-vaccination.module')                  .then((m) => m.MyVaccinationModule);
const TravelHealthInfoModule        = () => import('./views/travel-health-info/travel-health-info.module')          .then((m) => m.TravelHealthInfoModule);
const VaccineRecordModule           = () => import('./views/vaccine-record/vaccine-record.module')                  .then((m) => m.VaccineRecordModule);
const SettingModule                 = () => import('./views/setting/setting.module')                                .then((m) => m.SettingModule);
const IdentificationDetailsModule   = () => import('./views/identification-details/identification-details.module')  .then((m) => m.IdentificationDetailsModule);
const NotificationsModule           = () => import('./views/notifications/notifications.module')                    .then((m) => m.NotificationsModule);
const HomeModule                    = () => import('./views/home/home.module')                                      .then((m) => m.HomeModule);
const DashboardModule               = () => import('./views/dashboard/dashboard.module')                            .then((m) => m.DashboardModule);

const routes: Routes = [
  {
    path: '',
    loadChildren: LandingPageModule
  },
  {
    path: 'home',
    loadChildren: HomeModule
  },
  {
    path: 'create-account',
    loadChildren: RootModule
  },
  {
    path: 'user',
    loadChildren: ScannerModule
  },
  {
    path: 'dashboard',
    loadChildren: DashboardModule
  },
  {
    path: 'login',
    loadChildren: LoginModule
  },
  {
    path: 'account-type',
    loadChildren: AccountTypeModule
  },
  {
    path: 'forget-password',
    loadChildren: PasswordModule
  },
  {
    path: 'signup',
    loadChildren: SignUpModule
  },
  {
    path: 'my-vaccination',
    loadChildren: MyVaccinationModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'travel-health-info',
    loadChildren: TravelHealthInfoModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'vaccine-record',
    loadChildren: VaccineRecordModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'identification-details',
    loadChildren: IdentificationDetailsModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: SettingModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'notification',
    loadChildren: NotificationsModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'vaccineClinics',
    component: MapComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'crisis-line',
    component: CrisisLineComponent,
    canActivate: [AuthGuard],
  },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'help', component: HelpComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-condition', component: TermsConditionComponent },
  { path: 'about', component: AboutMComponent },
  { path: 'helps', component: HelpMComponent },
  { path: 'privacy-policies', component: PolicyMComponent },
  { path: 'terms-conditions', component: TermsMComponent },
  { path: 'apps', component: AppNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
