import { Injectable, WritableSignal } from '@angular/core';

import { signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  public readonly requestCount: WritableSignal<number> = signal<number>(0);

  public showSpinner(): void {
    this.requestCount.update((count) => count + 1);
  }

  public hideSpinner(): void {
    this.requestCount.update((count) => Math.max(0, count - 1));
  }
}
